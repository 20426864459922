export const ERROR_MESSAGE: any = {
    invalid_grant: "Invalid Credentials",
    code_401: "We are sorry, you are not authorized.",
    InvalidToken: "InvalidToken",
    email_not_confirmed: "email_not_confirmed",
    admin_approval_pending: "Your account is in pending for Admin approval.",
  };


export const acceptedFormats = [
  "application/pdf",
  ".pdf",
  ".xls",
  ".xlsx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];  

export interface IFile {
  fileName: string;
  file: any;
}