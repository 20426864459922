import useData from "hooks/useData";
import { useContext, useEffect, useState } from "react";
import { FiSettings, FiX } from "react-icons/fi";
import WebGLContext from "./WebGlContext";
import Area from "components/charts/Area/Area";
import ExpressionBigGraph from "components/charts/unity/bigChart/expressions";
import classNames from "utilities/ClassNames";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { createTicket } from "api/tickets";
import Chart from "./Chart";
import useApp from "hooks/useApp";
import { BiExpand } from "react-icons/bi";
import BorderGradient from "components/shared/BorderGradient/BorderGradient";

const defaultDataDetails = {
  name: null,
  nameIdentifier: null,
  uom: null,
  id: null,
  days: 0,
  type: "",
  tagNameIdentifier: "",
};

const Charts = ({ setModalOpen }: any) => {
  // const { isScaled } = useScreen();
  const {
    chartData,
    handleModalLoader,
    handleCloseModal,
    modalOpen,
    bubbleDetails,
    popupBubbleId,
    configureBubbleHandler,
    refreshUnityData,
  } = useContext(WebGLContext);
  const [dataCollection, setData] = useState<any>([]);
  const [containerClassName, setClassName] = useState<string>("");
  const [initialClassName, setInitialClassName] = useState<string>("");
  const { pastDate: selectedPastDate } = useData();

  const [showStatus, setStatus] = useState<boolean>(false);

  const [showCollection, enableCollection] = useState<boolean>(true);
  const [isGraphPopup, setGraphPopup] = useState<boolean>(false);

  const [screen, setScreen] = useState("normal");
  const [selected, setSelected] = useState<any>(null);

  const type = bubbleDetails?.type ? bubbleDetails.type : "";

  const [Editable, setEditable] = useState(false);
  const { userRole } = useApp();

  const [newTicket, setNewTicket] = useState<any>({
    comments: "",
    description: "",
    priority: null,
    severity: null,
    tagId: null,
    title: "",
  });

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setNewTicket({ ...newTicket, [e.target.id]: e.target.value });
  };

  const handleNewTicket = async (event: any) => {
    event.preventDefault();
    const response: any = await createTicket({
      comments: newTicket.comments,
      description: newTicket.description,
      priority: parseInt(newTicket.priority),
      severity: parseInt(newTicket.severity),
      tagId: selected.id,
      title: newTicket.title,
    });
    if (response.status === 200) {
      setScreen("normal");
    }
    setNewTicket({
      comments: "",
      description: "",
      priority: null,
      severity: null,
      tagId: null,
      title: "",
    });
  };

  useEffect(() => {
    if (userRole?.toLowerCase() == "admin" || userRole?.toLowerCase() == "manager") {
      setEditable(true);
    }
  }, [userRole]);

  useEffect(() => {
    if (!showCollection && initialClassName !== "gauge-chart-6-tags") {
      setClassName("gauge-chart-6-tags");
    } else {
      setClassName(initialClassName);
    }
  }, [showCollection, initialClassName]);

  useEffect(() => {
    const controller = new AbortController();
    let isApiSubscribed = true;
    if (isApiSubscribed) {
      handleModalLoader();
      if (bubbleDetails?.type === "tag" && !selectedPastDate) setStatus(true);
    }
    return () => {
      isApiSubscribed = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (chartData) {
      setData(chartData);
    }
  }, [chartData]);

  const ticketCreation = !showCollection && !isGraphPopup;
  const configureBubble = () => {
    if (popupBubbleId) {
      configureBubbleHandler(popupBubbleId);
      setModalOpen(false);
    }
  };

  const containerClasses = classNames(
    "mt-7",
    dataCollection.length > 1 && dataCollection.length <= 4 ? "lg:max-w-[55vw] 2xl:max-w-[47vw]" : "",
    dataCollection.length > 4 ? "sm:max-w-[70vw] ml-12" : "",
    dataCollection.length === 1 ? "sm:max-w-[24vw]" : "",
    dataCollection.length < 3 ? "h-[36vh]" : "min-h-[72vh]",
    "w-full gradient p-0.5 rounded-md z-30 absolute flex"
  );

  const chartContainerClasses = classNames(
    dataCollection.length > 1 && dataCollection.length <= 4 ? "grid-cols-2" : "",
    dataCollection.length > 2 ? "grid-rows-2" : "",
    dataCollection.length > 4 ? "grid-cols-3" : "",
    "grid gap-3 h-full");

  return (
    <>
      {screen === "ticket" && (
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div
            className={classNames(
              "h-full",
              "absolute z-20 inset-0 w-full bg-black/70"
            )}
          />

          <div className={classNames("top-1/2", "w-full h-auto gradient p-0.5 rounded-md z-30 mt-7 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[850px]")}>
            <div className="bg-dark pl-3 pt-3 pb-3 w-full rounded-md h-full max-h-[75vh] overflow-hidden overflow-y-scroll">
              <div className="flex flex-col py-3">
                <div className="flex justify-between">
                  <h1>Add trouble ticket</h1>
                  <div
                    className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                    onClick={() => setScreen("normal")}
                  >
                    <FiX size={25} />
                  </div>
                </div>
                <form className="mt-3" onSubmit={handleNewTicket}>
                  <div className="py-1">
                    <label htmlFor="title">Issue Title</label>
                    <input
                      className="input"
                      onChange={handleChange}
                      type="text"
                      id="title"
                      value={newTicket.title}
                    />
                  </div>

                  <div className="py-1">
                    <label htmlFor="description">Issue description</label>
                    <textarea
                      className="input"
                      onChange={handleChange}
                      id="description"
                      value={newTicket.description}
                    />
                  </div>

                  <div className="py-1">
                    <label htmlFor="comments">Resolution</label>
                    <textarea
                      className="input"
                      onChange={handleChange}
                      id="comments"
                      value={newTicket.comments}
                    />
                  </div>

                  <div className="py-2 flex justify-between gap-5">
                    <div className="flex flex-col gap-1 w-full">
                      <label>Severity</label>
                      <Select
                        value={newTicket.severity}
                        onValueChange={(value: any) =>
                          setNewTicket({ ...newTicket, severity: value })
                        }
                      >
                        <SelectTrigger className="w-full border-slate">
                          <SelectValue placeholder="severity" />
                        </SelectTrigger>
                        <SelectContent className="bg-dark border-slate">
                          <SelectItem value="1">1</SelectItem>
                          <SelectItem value="2">2</SelectItem>
                          <SelectItem value="3">3</SelectItem>
                          <SelectItem value="4">4</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex flex-col gap-1 w-full">
                      <label>Priority</label>
                      <Select
                        value={newTicket.priority}
                        onValueChange={(value: any) =>
                          setNewTicket({ ...newTicket, priority: value })
                        }
                      >
                        <SelectTrigger className="w-full border-slate">
                          <SelectValue placeholder="Priority" />
                        </SelectTrigger>
                        <SelectContent className="bg-dark border-slate">
                          <SelectItem value="3">Height</SelectItem>
                          <SelectItem value="2">Medium</SelectItem>
                          <SelectItem value="1">Low</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                  {/* Ticket Dialog footer */}
                  <div className="flex justify-end gap-5 mt-2">
                    <button
                      className="cursor-pointer btn-sec"
                      onClick={() => setScreen("normal")}
                    >
                      Cancel
                    </button>
                    <button className="cursor-pointer btn" type="submit">
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div >
      )}
      {!chartData && <div>Loading</div>}
      {
        screen === "normal" && chartData && dataCollection.length && (
          <div className="fixed inset-0 z-10 w-full h-screen">
            <div
              className={classNames(
                "h-full",
                "absolute z-20 inset-0 w-full bg-black/70"
              )}
            />
            <div className="w-screen h-screen flex flex-wrap items-center justify-center">
              <div
                className={containerClasses}
              >
                <div className={classNames("bg-dark px-3 pt-3 pb-3 w-full rounded-md flex flex-wrap")}>
                  {/* Header */}
                  <div className="flex justify-between w-full h-min -mb-4 3xl:mb-0">
                    <div className="flex gap-3 items-center">
                      <h1 className="text-2xl font-bold text-green">
                        {bubbleDetails?.name}
                      </h1>
                      {popupBubbleId && Editable && (
                        <div
                          className="cursor-pointer text-green"
                          onClick={configureBubble}
                        >
                          <FiSettings size={20} />
                        </div>
                      )}
                    </div>
                    <div
                      className="bg-green py-1 px-1.5 rounded-md cursor-pointer grid place-content-center"
                      onClick={handleCloseModal}
                    >
                      <FiX size={20} />
                    </div>
                  </div>
                  <div className={classNames("mt-5 w-full",dataCollection.length<=2?"h-[83%]":"h-[90%]")}>
                  {showCollection && dataCollection && (
                      <div className={chartContainerClasses}>
                        {dataCollection.map((data: any, index: any) => (
                          <div key={index}>
                            <Chart
                              data={data}
                              key={index}
                              screen={screen}
                              setScreen={setScreen}
                              setSelected={setSelected}
                              refreshUnityData={refreshUnityData}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        screen === "big" && (
          <div className="absolute z-20 left-[0%] top-[0%] inset-0 w-full bg-black/70 flex justify-center items-center">
            <div className="fixed bg-dark rounded-xl">
              <>
                <BorderGradient>
                  <div className="bg-dark rounded-md">
                    <div className="flex justify-end p-2">
                      <button
                        className="cursor-pointer bg-green rounded-md p-1"
                        onClick={() => setScreen('normal')}
                      >
                        <BiExpand size={18} />
                      </button>
                    </div>
                    <div className="min-w-[60vw] px-4">
                      <BorderGradient>
                        <div className="bg-dark px-1 py-2 rounded-md h-[60vh]">
                          <Area item={selected} type='tag' areaType="yearly" renderType="lazyLoading" maintainAspectRatio={false} />
                        </div>
                      </BorderGradient>
                    </div>
                    <div className="p-4">
                      <div className="flex items-center">
                        <p>Tag Name:</p>
                        <div className="flex-1" />
                        <p>{selected.name}</p>
                      </div>
                      <div className="flex items-center">
                        <p>Description:</p>
                        <div className="flex-1" />
                        <p>{selected.desc}</p>
                      </div>
                      <div className="flex items-center">
                        <p>Engineering Unit:</p>
                        <div className="flex-1" />
                        <p>{selected.uom}</p>
                      </div>
                    </div>
                  </div>
                </BorderGradient>
              </>
            </div>
          </div>
        )
      }
    </>
  );
};

export default Charts;
