import BorderGradient from "components/shared/BorderGradient/BorderGradient";
import TableManager from "components/TableManager/TableManager";
import DataTable from "components/DataTable/DataTable";
import { useState, useMemo, useEffect } from "react";
import Debounce from "utilities/Debounce";
import capitalizeFirstLetter from "utilities/capitalize";
import Loader, { SmallestLoader } from "components/shared/Loader";

const Assets = ({ data, isLoading, type, onClickHandler, onActionClickHandler, onAddItem, onMapping, setSelectedPage, selectedItem }: any) => {

  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const [keyword, setKeyword] = useState('');
  const [isMapping, setIsMapping] = useState(false);

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  useEffect(() => {
    if (type === 'tags') {
      setIsMapping(true);
    }

    if (type !== 'tags') {
      setIsMapping(false);
    }
  }, [type]);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter((item: any) => {
        const hasTag = item.tagNameIdentifier ?
          (item.tagNameIdentifier.toLowerCase().includes(keyword) ||
            item.tagNameIdentifier.toLowerCase() === keyword) : false;
        const hasName = item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword;
        return hasTag || hasName;
      });
    }
    return items;
  }, [data, debouncedKeyword]);

  return (
    <div className="h-full">
      <BorderGradient className="h-full">
        <div className="bg-black rounded-md px-1.5 py-1.5 2xl:py-4 2xl:px-3 overflow-hidden h-full">
          <div className="w-full flex gap-4">
            <div className="flex items-center relative">
              <h2 className="text-lg font-bold mr-2">{capitalizeFirstLetter(type)}</h2>
              {isLoading && <span className=""><SmallestLoader /></span>}
            </div>
            <div className="w-full">
              <TableManager type={type} setKeyword={setKeyword} btnAction={onAddItem} onMapping={onMapping} setSelectedPage={setSelectedPage} isMapping={isMapping} searchRightSide={true} />
            </div>
          </div>
          {!rows && <div className="w-full h-full flex justify-center items-center"><Loader /></div>}
          {rows &&
            <div className="h-[90%] w-full overflow-auto relative">
              <DataTable hasPins={false} hasActions={true} type={type} rows={rows} onClickHandler={onClickHandler} onActionClickHandler={onActionClickHandler} setSelectedPage={setSelectedPage} selectedItem={selectedItem} />
              {rows.length === 0 &&
                <span className="absolute top-16 w-full text-center text-xl">No results</span>
              }
            </div>}
        </div>
      </BorderGradient>
    </div>
  );
};

export default Assets;